export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [0,2];

export const dictionary = {
		"/account/[slug]": [~5],
		"/account/[slug]/[...deepslug]": [~6],
		"/checkout/[slug]": [~7],
		"/checkout/[slug]/[...deepslug]": [~8],
		"/customer/[slug]": [~9],
		"/customer/[slug]/[...deepslug]": [~10],
		"/editor/[slug]": [~11],
		"/editor/[slug]/[...deepslug]": [~12],
		"/products/[slug]": [~13],
		"/products/[slug]/[...deepslug]": [~14],
		"/[...slug]": [~4,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';